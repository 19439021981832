import React from 'react'

import styles from './SelectTypeLogin.module.css'
import {EMAIL_TYPE, LOGIN_TYPE, PHONE_TYPE} from "../../../../redux/auth/authTypes";
import FormWrapper from "../../FormWrapper/FormWrapper";

function SelectTypeLogin({auth, setAuth}) {
    return (
        <FormWrapper title={"Type of login:"} htmlFor={"loginType"}>
            <select className={styles["form-input-select"]} value={auth.typeLogin} onChange={e => setAuth({...auth, typeLogin: e.target.value})}
                    id="loginType">
                <option value={LOGIN_TYPE}>Login</option>
                <option value={EMAIL_TYPE}>Email</option>
                <option value={PHONE_TYPE}>Phone</option>
            </select>
        </FormWrapper>
    )
}

export default SelectTypeLogin