import React from 'react';
import styles from "./Input.module.css";
import FormWrapper from "../FormWrapper/FormWrapper";
import {EMAIL_TYPE, LOGIN_TYPE} from "../../../redux/auth/authTypes";

function InputLogin(props) {
    const {auth, setAuth} = props;

    let placeHolderLogin = EMAIL_TYPE === auth.typeLogin ? "Email 'example@email.com'" :
        LOGIN_TYPE === auth.typeLogin ? "Login" : "Phone";

    if(!auth.typeLogin) {
        placeHolderLogin = "Login";
    }

    return (
        <FormWrapper title={"Login:"} htmlFor={"login"}>
            <input type="text" name="login" className={styles["form-input-password-text"]} id="login"
                   placeholder={placeHolderLogin} value={auth.login}
                   onChange={e => setAuth({...auth, login: e.target.value})}/>
        </FormWrapper>
    );
}

export default InputLogin;