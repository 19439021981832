import './App.css';
import AuthorizationForm from './components/Background/AuthorizationForm/AuthorizationForm';
import {Route, Routes} from "react-router-dom";
import Background from "./components/Background/Background";
import {Provider} from "react-redux";
import store from "./redux/store";
import HomePage from "./components/Background/HomePage/HomePage";
import {loadTokenFromStorage} from "./redux";
import RegistrationForm from "./components/Background/RegistrationForm/RegistrationForm";
import OAuth from "./components/Background/OAuth/OAuth";

function App() {
    store.dispatch(loadTokenFromStorage())
  return (
      <Background>
          <Provider store={store}>
              <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/login" element={
                      <AuthorizationForm />
                  } />
                  <Route path="/registration" element={
                      <RegistrationForm />
                  } />

                  <Route path="/oauth" element={
                      <OAuth />
                  } />
              </Routes>
          </Provider>
      </Background>
  );
}

export default App;
