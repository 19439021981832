import React from 'react';
import styles from "./Input.module.css";
import FormWrapper from "../FormWrapper/FormWrapper";

function InputRepeatPassword({auth, setAuth}) {
    const typePassword = auth.hiddenPass ? "password" : "text";

    return (
        <FormWrapper title={"Repeat password:"} htmlFor={"repeatPassword"}>
            <input type={typePassword} name="repeatPassword" className={styles["form-input-password-text"]}
                   id="repeatPassword" placeholder={"Repeat password"} value={auth.repeatPassword}
                   onChange={e => setAuth({...auth, repeatPassword: e.target.value})}/>
        </FormWrapper>
    );
}

export default InputRepeatPassword;