import React from 'react'

import yandexButtonStyle from './OAuthButton/YandexAuthButton.module.css';
import googleButtonStyle from './GoogleAuthButton.module.css';
import outerContainerStyle from './OAuthButton/OuterServicesContainer.module.css';

import OAuthButton from './OAuthButton/OAuthButton.jsx';
import {connect} from "react-redux";
import {redirectToGoogle, redirectToYandex} from "../../../redux";
import FormWrapper from "../FormWrapper/FormWrapper";

function OuterServicesContainer(props) {
    return (
        <FormWrapper>
            <div className={outerContainerStyle["outer-services-container"]}>
                <OAuthButton className={outerContainerStyle["outer-service"]} styles={yandexButtonStyle} text="Yandex"
                             onClick={props.redirectYandex}/>
                <OAuthButton className={outerContainerStyle["outer-service"]} styles={googleButtonStyle} text="Google"
                             onClick={props.redirectToGoogle}/>
            </div>
        </FormWrapper>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        redirectYandex: () => {
            dispatch(redirectToYandex())
        },
        redirectToGoogle: () => {
            dispatch(redirectToGoogle())
        },
    }
}

export default connect(null, mapDispatchToProps)(OuterServicesContainer);