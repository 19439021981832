import React from 'react'

import styles from "./AuthButton.module.css"
import FormWrapper from "../FormWrapper/FormWrapper";

function AuthButton(props) {
    const {text} = props;

    return (
        <FormWrapper style={{marginTop: "5rem"}}>
            <div id={styles["container-button"]}>
                <button type='submit' id={styles["button"]}>{text}</button>
            </div>
        </FormWrapper>
    )
}

export default AuthButton