import authStyles from "../AuthorizationForm.module.css"
import React, {useState} from 'react'
import OuterServicesContainer from "../OuterServicesContainer/OuterServicesContainer";
import AuthButton from "../AuthButton/AuthButton";
import {register} from "../../../redux";
import {connect} from "react-redux";
import {useSearchParams} from "react-router-dom";
import useConfig from "../../../hooks/config";
import InfoContainer from "../InfoContainer/InfoContainer";
import LinkToAnotherForm from "../LinkToAnotherForm/LinkToAnotherForm";
import RevealPassword from "../RevealPassword/RevealPassword";
import InputPassword from "../Inputs/InputPassword";
import InputLogin from "../Inputs/InputLogin";
import InputName from "../Inputs/InputName";
import InputRepeatPassword from "../Inputs/InputRepeatPassword";

function RegistrationForm(props) {

    const [auth, setAuth] = useState({
        login: "",
        password: "",
        hiddenPass: true,
        repeatPassword: "",
        name: ""
    })

    const [searchParams] = useSearchParams()
    const homePage = useConfig("homePage");

    const redirectPath = searchParams.get("redirect") || homePage;

    const submitHandler = (e) => {
        e.preventDefault();

        props.register(auth, () => {
            window.location.replace(redirectPath);
        })
    }

    return (
        <div className={authStyles["Auth"]}>
            <h1 className={authStyles["Auth-header"]}>Registration</h1>
            <form className={authStyles["form"]} onSubmit={submitHandler}>
                <InputLogin auth={auth} setAuth={setAuth}/>
                <InputName auth={auth} setAuth={setAuth}/>
                <InputPassword auth={auth} setAuth={setAuth}/>
                <InputRepeatPassword auth={auth} setAuth={setAuth}/>
                <RevealPassword auth={auth} setAuth={setAuth}/>

                <InfoContainer/>

                <OuterServicesContainer className={authStyles["form-wrapper"]}/>

                <AuthButton text="Register"/>

                <LinkToAnotherForm text={"I have an account"} pathname={"/login"}/>
            </form>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        register: (authData, action) => dispatch(register(authData, action))
    }
}

export default connect(null, mapDispatchToProps)(RegistrationForm);