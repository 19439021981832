import React from 'react';
import {Link, useSearchParams} from "react-router-dom";
import FormWrapper from "../FormWrapper/FormWrapper";
import authStyles from "../AuthorizationForm.module.css";

function LinkToAnotherForm(props) {
    const [searchParams] = useSearchParams()

    const {pathname, text} = props;

    return (
        <FormWrapper style={{display: "flex", justifyContent: "center"}}>
            <Link to={{pathname: pathname, search: searchParams.get("redirect") && `?redirect=${encodeURIComponent(searchParams.get("redirect"))}`}} replace={true} className={authStyles["link-bottom"]}>{text}</Link>
        </FormWrapper>
    );
}

export default LinkToAnotherForm;