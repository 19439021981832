import React from 'react';
import styles from './Input.module.css';
import FormWrapper from "../FormWrapper/FormWrapper";

function InputName({auth, setAuth}) {
    return (
        <FormWrapper title={"Name:"} htmlFor={"name"}>
            <input type="text" name="name" className={styles["form-input-password-text"]} id="name" value={auth.name} onChange={e => setAuth({...auth, name: e.target.value})} placeholder={"Name"}/>
        </FormWrapper>
    );
}

export default InputName;