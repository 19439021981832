import React from 'react'
import styles from "./FormWrapper.module.css"

function FormWrapper(props) {
    return (
        <div className={styles["form-wrapper"]} style={props.style}>
            {
                props.title ?
                (
                    <>
                        <label htmlFor={props.htmlFor} className={styles["label"]}>{props.title}</label>
                        <div className={styles["marginTop"]}>
                            {props.children}
                        </div>
                    </>
                ) : props.children
            }

        </div>
    )
}

export default FormWrapper