import React from 'react';
import FormWrapper from "../FormWrapper/FormWrapper";
import styles from "./InfoContainer.module.css";
import {connect} from "react-redux";

function InfoContainer(props) {
    return (
        <>
            {
                props.loading && <FormWrapper><span>Loading...</span></FormWrapper>
            }
            {
                props.error !== '' &&
                <FormWrapper><span className={styles["error"]}>{props.error}</span></FormWrapper>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading || state.outerAuth.loading,
        error: state.auth.error + " " + state.outerAuth.error
    }
}

export default connect(mapStateToProps)(InfoContainer);