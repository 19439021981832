import {EMAIL_TYPE, SEND_LOGIN_FAILURE, SEND_LOGIN_REQUEST, SEND_LOGIN_SUCCESS} from "./authTypes";
import useConfig from "../../hooks/config";
import axios from "axios";
import {useSearchParams} from "react-router-dom";

const validator = require("email-validator");

const TOKEN_KEY = "token";

export const saveTokenToStorage = (token) => {
    return () => {
        localStorage.setItem(TOKEN_KEY, token.token);
    }
}

export const loadTokenFromStorage = () => {
    return (dispatch) => {
        const token = {token: localStorage.getItem(TOKEN_KEY)}
        dispatch(sendLoginSuccess(token));
    }
}

export const login = (authData, searchParams) => {
    return (dispatch) => {
        const loginURI = useConfig("accountsHOST") + useConfig("loginURL");

        const errorMessage = validateLogin(authData);
        if(errorMessage){
            dispatch(sendLoginFailure(errorMessage));
            return;
        }

        const homePage = useConfig("homePage");
        const redirectPath = searchParams.get("redirect") || homePage;

        dispatch(sendLoginRequest());
        axios.post(
            loginURI,
            {
                login: authData.login.trim(),
                password: authData.password,
                typeOfLogin: authData.typeLogin
            }
        ).then(response => {
            const token = response.data;
            dispatch(sendLoginSuccess(token));
            dispatch(saveTokenToStorage(token))

            window.location.replace(redirectPath);
        })
        .catch(error => {
            if(error.status === 401){
                dispatch(sendLoginFailure("Login or password is incorrect"));
            }
            else{
                dispatch(sendLoginFailure(error.message));
            }
        })
    }
}

const validateLogin = (authData) => {
    let errorMessage = ''

    if(authData.password === ''){
        errorMessage = "Password should not be empty";
    }
    else if(authData.login.trim() === ''){
        errorMessage = "Login should not be empty";
    }
    else if((authData.typeLogin === EMAIL_TYPE) && !validator.validate(authData.login)){
        errorMessage = "Email should be valid";
    }

    return errorMessage;
}

export const register = (authData, actionSuccess) => {
    return (dispatch) => {
        console.log(authData);

        const registerURI = useConfig("accountsHOST") + useConfig("registrationURL");

        const errorMessage = validateRegister(authData);
        if(errorMessage){
            dispatch(sendLoginFailure(errorMessage));
            return;
        }

        dispatch(sendLoginRequest());

        axios.post(registerURI,
            {
                login: authData.login.trim(),
                password: authData.password,
                name: authData.name.trim(),
            })
            .then(response => {
                const token = response.data;
                dispatch(sendLoginSuccess(token));
                dispatch(saveTokenToStorage(token))
                actionSuccess(response);
            })
            .catch(error => {
                dispatch(sendLoginFailure(error.message));
            })
    }
}

const validateRegister = (authData) => {
    let errorMessage = ''

    if(authData.password === ''){
        errorMessage = "Password should not be empty";
    }
    else if(authData.password.length < 8){
        errorMessage = "Password must be greater than 8 characters";
    }
    else if(authData.login.trim() === ''){
        errorMessage = "Login should not be empty";
    }
    else if(authData.password !== authData.repeatPassword){
        errorMessage = "Passwords should be equal";
    }
    else if(authData.name.trim() === ''){
        errorMessage = "Name should not be empty";

    }

    return errorMessage;
}

export const sendLoginRequest = () => {
    return {
        type: SEND_LOGIN_REQUEST
    }
}

export const sendLoginSuccess = (token) => {
    return {
        type: SEND_LOGIN_SUCCESS,
        payload: token
    }
}

export const sendLoginFailure = (error) => {
    return {
        type: SEND_LOGIN_FAILURE,
        payload: error
    }
}