import React from 'react';
import styles from "./Input.module.css";
import FormWrapper from "../FormWrapper/FormWrapper";

function InputPassword({auth, setAuth}) {
    const typePassword = auth.hiddenPass ? "password" : "text";

    return (
        <FormWrapper title={"Password:"} htmlFor={"password"}>
            <input type={typePassword} name="password" className={styles["form-input-password-text"]}
                   id="password" placeholder={"Password"} value={auth.password}
                   onChange={e => setAuth({...auth, password: e.target.value})}/>
        </FormWrapper>
    );
}

export default InputPassword;