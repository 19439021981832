import authStyles from "../AuthorizationForm.module.css"
import React, {useState} from 'react'
import OuterServicesContainer from "../OuterServicesContainer/OuterServicesContainer";
import AuthButton from "../AuthButton/AuthButton";
import {LOGIN_TYPE} from "../../../redux/auth/authTypes";
import {login} from "../../../redux";
import {connect} from "react-redux";
import InfoContainer from "../InfoContainer/InfoContainer";
import SelectTypeLogin from "./SelectTypeLogin/SelectTypeLogin";
import InputLogin from "../Inputs/InputLogin";
import InputPassword from "../Inputs/InputPassword";
import RevealPassword from "../RevealPassword/RevealPassword";
import {useSearchParams} from "react-router-dom";
import LinkToAnotherForm from "../LinkToAnotherForm/LinkToAnotherForm";

function AuthorizationForm(props) {
    
    const [auth, setAuth] = useState({
        login: "",
        typeLogin: LOGIN_TYPE,
        password: "",
        hiddenPass: true
    })

    const [searchParams] = useSearchParams()

    const submitHandler = (e) => {
        e.preventDefault();

        props.login(auth, searchParams)
    }
    
    return (
        <div className={authStyles["Auth"]}>
            <h1 className={authStyles["Auth-header"]}>Authorization</h1>
            <form className={authStyles["form"]} onSubmit={submitHandler}>
                <SelectTypeLogin auth={auth} setAuth={setAuth} />
                <InputLogin auth={auth} setAuth={setAuth} />
                <InputPassword auth={auth} setAuth={setAuth}/>
                <RevealPassword auth={auth} setAuth={setAuth}/>

                <InfoContainer/>

                <OuterServicesContainer className={authStyles["form-wrapper"]} />

                <AuthButton text="Log in"/>

                <LinkToAnotherForm text={"I don't have an account"} pathname={"/registration"}/>
            </form>
        </div>
    )
}

const mapDispatchToProps = dispatch => {

    return {
        login : (authData, searchParams) => dispatch(login(authData, searchParams))
    }
}

export default connect(null, mapDispatchToProps)(AuthorizationForm);