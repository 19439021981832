import React from 'react';
import styles from "./RevealPassword.module.css";
import FormWrapper from "../FormWrapper/FormWrapper";

function RevealPassword({auth, setAuth}) {
    return (
        <FormWrapper>
            <input type="checkbox" name='reveal-password' id={styles["reveal-password"]}
                   checked={!auth.hiddenPass}
                   onChange={e => setAuth({...auth, hiddenPass: !e.target.checked})}/>
            <span className={styles["secondary-text"]}>Reveal password</span>
        </FormWrapper>
    );
}

export default RevealPassword;