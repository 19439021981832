import {SEND_REDIRECT_URL_FAILURE, SEND_REDIRECT_URL_REQUEST, SEND_REDIRECT_URL_SUCCESS} from "./outerAuthTypes";
import axios from "axios";
import useConfig from "../../hooks/config";

export const sendRedirectUrlRequest = () => {
    return {
        type: SEND_REDIRECT_URL_REQUEST
    }
}

export const sendRedirectUrlSuccess = (redirectUri) => {
    return {
        type: SEND_REDIRECT_URL_SUCCESS,
        payload: redirectUri
    }
}

export const sendRedirectUrlFailure = (error) => {
    return {
        type: SEND_REDIRECT_URL_FAILURE,
        payload: error
    }
}

const redirectToOuterService= (service) => {
    return (dispatch) => {
        const requestRedirectUri = useConfig("accountsHOST") + useConfig("authorizationBaseUrl") + "/" + service;

        dispatch(sendRedirectUrlRequest());

        // fetch(requestRedirectUri, {
        //     method: "GET",
        //     credentials: "same-origin"
        // })
        //     .then(response => {
        //         const redirectUrl = response.data.redirectUrl;
        //
        //         window.location.replace(redirectUrl);
        //     })
        //     .catch(error => {
        //         dispatch(sendRedirectUrlFailure(error));
        //     })

        axios.get(requestRedirectUri, {
            withCredentials: true
        })
            .then(response => {
                const redirectUrl = response.data.redirectUrl;
                dispatch(sendRedirectUrlSuccess(redirectUrl));

                window.location.replace(redirectUrl);
            })
            .catch(error => {
                dispatch(sendRedirectUrlFailure(error));
            })
    }
}

export const redirectToYandex = () => {
    return (dispatch) => {
        dispatch(redirectToOuterService("yandex"));
    }
}

export const redirectToGoogle = () => {
    return (dispatch) => {
        dispatch(redirectToOuterService("google"));
    }
}