import React, {useEffect, useState} from 'react';
import useConfig from "../../../hooks/config";
import {useLocation} from "react-router-dom";
import axios from "axios";

function OAuth(props) {
    const location = useLocation();
    const callBackURI = useConfig("accountsHOST") + useConfig("callbackBaseUrl") + location.search;
    // const callBackURI = "http://localhost:8080/oauth2/callback/auth0" + location.search;
    console.log(callBackURI);

    const [data, setData] = useState(null)
    useEffect(() => {
        axios(callBackURI, {
            method: "get",
            withCredentials: true,
            credentials: "include"
        })
            .then(response => {
                setData(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }, [])

    return (
        <div>
            {data && data.answerType}<br/>
            {data && data.data && data.data.email}<br/>
            {data && data.data && data.data.name}<br/>
            {data && data.data && data.data.familyName}<br/>
            {data && data.data && data.data.login}
        </div>
    );
}

export default OAuth;